body {
  font-family: "Open Sans Condensed";
  padding: 20px 60px;
}

a {
  text-decoration: none;
  color: #000;
}

* {
  box-sizing: border-box;
}
